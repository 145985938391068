"use strict"

import parseDataUrl from "parse-data-url"
import fetchWrapper from "../functions/fetchWrapper"
import qs from "qs"
import _ from "lodash"
import he from "he"
import { setMaintenanceMode } from "./session"
import moment from "moment"

export function getToken() {
  return async (dispatch) => {
    try {
      let response = await fetchWrapper.get("/session/token")
      if (response.ok) {
        let status = await response.text()
        if (status) {
          return dispatch({ type: "session/getToken", payload: status })
        }
      }
    } catch (err) {
      return err
    }
  }
}

/**
 * This lets us get the user ID so we can fetch the full user object later
 */
export function getUserId(mobile = false, cookie = null) {
  return async (dispatch, getState) => {
    if (mobile) {
      const { session } = getState()
      cookie = session.cookie[0]
    }

    try {
      let response = await fetchWrapper.get("/api/user/user/me", cookie)
      if (response.ok) {
        let status = await response.json()

        if (status) {
          let userId = status[0].uid[0].value
          let group = status[1]
          let trial

          if (group && group.type[0].target_id === "partner") {
            group.field_hq = [{ value: true }]
            trial = false
          } else {
            trial = group ? group.field_trial[0].value : false
          }

          const subscriptionEnd =
            status.org_subscription_info &&
            status.org_subscription_info.length &&
            !status.org_subscription_info.content &&
            status.org_subscription_info[
              status.org_subscription_info.length - 1
            ].field_subscription_end[0] &&
            status.org_subscription_info[
              status.org_subscription_info.length - 1
            ].field_subscription_end[0].value

          let action = {
            type: "session/getUserId",
            payload: {
              memberships: status.cross_org
                ? _.orderBy(status.cross_org, (org) => org.name)
                : [],
              customerSupportEmail: status.customer_success_email,
              subscriptionEnd: subscriptionEnd ? subscriptionEnd : null,
              userId,
              trial,
              roles: status[0].roles,
              orgRoles: status[2],
              group,
              loggedIn: status.logged_in,
              isMasquerading: status.masquerade,
              userCollection: status.user_collection,
            },
          }

          if (status[3]) {
            action.payload.subgroup = status[3][0]
            action.payload.subgroups = status[3]
          }
          if (status[4]) {
            action.payload.collection = status[4]
          }

          return dispatch(action)
        }
      } else {
        response
          .json()
          .then((data) => {
            if (data.maintenance) {
              dispatch(setMaintenanceMode(true))
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getUser(uid, mobile = false, cookie = null) {
  return async (dispatch, getState) => {
    if (mobile) {
      const { session } = getState()
      cookie = session.cookie[0]
    }

    uid = uid.userId ? uid.userId : uid

    try {
      // this returns the full user object, or a 401 if the user is not logged in
      let response = await fetchWrapper.get(
        "/api/user/user?filter[uid][value]=" +
          uid +
          "&include=roles,user_picture,field_learning_buddy,field_job_title,field_rewind_image,field_learning_buddy,field_quick_actions",
        cookie
      )
      let timeSpent = await fetchWrapper.get(
        "/api/user-time-spent-pd-totals",
        cookie
      )

      let userImages = await fetchWrapper.get("/api/user-images", cookie)

      if (response.ok && timeSpent.ok && userImages.ok) {
        let status = await response.json()
        timeSpent = await timeSpent.json()
        userImages = await userImages.json()

        if (status) {
          let [user] = status.data

          user.coverPhoto =
            userImages.rows &&
            userImages.rows[0] &&
            userImages.rows[0].field_cover_photo
          user.userMobilePicture =
            userImages.rows &&
            userImages.rows[0] &&
            userImages.rows[0].user_picture

          user.estimatedTimeSpent = {
            total_assessment_time: timeSpent.total_assessment_time,
            total_time_spent: timeSpent.total_time_spent,
            total_user_event_time: timeSpent.total_user_event_time,
            total_ext_user_event_time: timeSpent.total_ext_user_event_time,
            badges_earned: timeSpent.user_badge_count,
            courses_completed: timeSpent.user_assessment_count,
            lps_completed: timeSpent.user_lp_count,
            events_attended: timeSpent.user_event_count,
          }
          user.learningBuddy =
            status.included &&
            status.included.find(
              (included) => included.type === "taxonomy_term--learning_buddy"
            )
          user.quickActions =
            status.included &&
            status.included.filter(
              (included) => included.type === "taxonomy_term--quick_links"
            )

          return dispatch({
            type: "session/userIsLoggedIn",
            payload: user,
            meta: {
              included: status.included,
            },
          })
        }
      }
      return dispatch({ type: "session/userIsAnonymous" })
    } catch (err) {
      console.log(err)
    }
  }
}

export function switchActiveMembership(selectedGroupId, targetURL) {
  return async (dispatch, getState) => {
    const { session } = getState()

    const targetMembership = session.memberships.find(
      (group) => group.id === selectedGroupId
    )

    let newProfile = {
      data: {
        id: session.user.id,
        type: "user--user",
        relationships: {
          field_active_membership: {},
        },
      },
    }

    if (session.userCollection) {
      if (selectedGroupId) {
        newProfile.data.relationships.field_active_membership = {
          data: {
            type: "group--organization",
            id: selectedGroupId,
          },
        }
      } else {
        newProfile.data.relationships.field_active_membership = {
          data: null,
        }
      }
    } else {
      newProfile.data.relationships.field_active_membership = {
        data: {
          type: targetMembership.type,
          id: targetMembership.id,
        },
      }
    }
    let body = JSON.stringify(newProfile)

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + session.user.id,
        session.token,
        body
      )

      if (response.ok) {
        window.location.href = targetURL
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateUserPicture(image) {
  return async (dispatch) => {
    dispatch({
      type: "session/updateUserPicture",
      payload: image,
    })
  }
}

export function updateUserCoverPhoto(image) {
  return async (dispatch) => {
    dispatch({
      type: "session/updateCoverPhoto",
      payload: image,
    })
  }
}

export function uploadImage(
  image,
  url,
  filename,
  learnerComments,
  assessment,
  mobileToken
) {
  return async (dispatch, getState) => {
    let token = mobileToken ? mobileToken : getState().session.token

    let uploadedImage

    if (!mobileToken) {
      uploadedImage = parseDataUrl(image).toBuffer()
    } else {
      uploadedImage = image
    }

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/octet-stream",
        "X-CSRF-Token": token,
        "Content-Disposition": 'file; filename="' + he.encode(filename) + '"',
      }),
      body: uploadedImage,
    }

    try {
      let response = await fetch(process.env.REACT_APP_API_URL + url, options)

      if (assessment) {
        let commentUpdate = {
          data: {
            id: assessment.id,
            type: "assessment_entity--assessment_entity",
            attributes: {
              field_learner_comments: learnerComments,
            },
          },
        }

        const body = JSON.stringify(commentUpdate)
        await fetchWrapper.patch(
          "/api/assessment_entity/assessment_entity/" + assessment.id,
          getState().session.token,
          body
        )
      }

      if (response.ok) {
        let data = await response.json()
        // Go ahead and return the image if we need to do something with it
        dispatch({
          type: "assessment/addImage",
          payload: data.data,
        })
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateProfileInfo({ user, jobTitleId }) {
  return async (dispatch, getState) => {
    const { jobTitles } = getState()

    dispatch({
      type: "session/updateUser",
      payload: user,
      jobTitle: jobTitles.data.find((title) => title.id === jobTitleId),
    })
  }
}
export function updateProfile(profile, session, removeImage) {
  return async (dispatch, getState) => {
    let jobTitle = null

    if (profile.jobTitle && profile.jobTitle !== "None") {
      jobTitle = {
        type: "taxonomy_term--job_titles",
        id: profile.jobTitle,
      }
    }

    let newProfile = {
      data: {
        id: session.user.id,
        type: "user--user",
        attributes: {
          field_first_name: profile.firstName,
          field_reviewer_email_frequency: profile.digestFreq,
          field_last_name: profile.lastName,
          field_bio: profile.bio,
          field_job_title_other: profile.jobTitleOther && profile.jobTitleOther,
          timezone: profile.timezone,
          field_email_opt_out: profile.field_email_opt_out
            ? ["blocked_user"]
            : [],
        },
        relationships: {
          field_job_title: {
            data: jobTitle,
          },
        },
      },
    }

    if (removeImage) {
      newProfile.data.relationships = {
        field_job_title: {
          data: jobTitle,
        },
        user_picture: {
          data: [],
        },
      }
    }

    let body = JSON.stringify(newProfile)

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + session.user.id,
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()
        if (!jobTitle) {
          window.location.reload()
        }

        dispatch({
          type: "session/updateUser",
          payload: data.data,
          jobTitle:
            jobTitle &&
            getState().jobTitles.data.find((title) => title.id === jobTitle.id),
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: "snackbar/update",
        payload: err.message,
      })
    }
  }
}

export function logout() {
  return async () => {
    try {
      let response = await fetchWrapper.get("/api/user/user/logout")
      if (response.ok) {
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getGroupMembers(subGroups, userSubGroups) {
  return async (dispatch) => {
    let query = {
      filter: {
        subgroup: {
          group: {
            conjunction: "OR",
          },
        },
      },
      include: "roles,user_picture,field_job_title",
    }

    if (subGroups.data.length) {
      subGroups.data.forEach((group, index) => {
        query.filter["subgroup-" + index] = {
          condition: {
            path: "field_subgroup",
            operator: "CONTAINS",
            value: group.attributes.label,
            memberOf: "subgroup",
          },
        }
      })
    } else {
      userSubGroups.forEach((label, index) => {
        query.filter["subgroup-" + index] = {
          condition: {
            path: "field_subgroup",
            operator: "CONTAINS",
            value: label,
            memberOf: "subgroup",
          },
        }
      })
    }

    try {
      let response = await fetchWrapper.get(
        "/api/user/user?" + qs.stringify(query)
      )
      let data = await response.json()

      dispatch({
        type: "sessionSlice/getGroupMembers",
        payload: data.data,
      })

      dispatch({
        type: "complianceSlice/getGroupMembers",
        payload: data.data,
        meta: {
          included: data.included,
        },
      })

      return data.data
    } catch (err) {
      console.log(err)
    }
  }
}

export function createBadgeCertificate(badge, mobile) {
  return async (dispatch, getState) => {
    const token = mobile
      ? getState().session.mobileToken.csrf_token
      : getState().session.token

    let body = {
      data: {
        type: "certificate_entity--badge",
        relationships: {
          field_user_badge: {
            data: {
              type: "user_badge--user_badge",
              id: badge.field_user_badge_uuid,
            },
          },
        },
      },
    }

    try {
      let response = await fetchWrapper.post(
        "/api/certificate_entity/badge",
        token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "profileSlice/addCertificateToBadge",
          payload: {
            badgeId: badge.id,
          },
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function createEventCertificate(event, mobile, isSharing) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const token = mobile ? session.mobileToken.csrf_token : session.token

    let eventBundle = "event_base"
    if (event.field_award_credit_for_conferenc) {
      eventBundle = "conference"
    }
    if (event.field_parent_event) {
      eventBundle = "conference_event"
    }

    let query = {
      filter: {
        "field_user_event.id": event.uuid,
      },
    }

    let bundle = "event"

    // Figure out total individual session credit if this is a session-PD based conference
    const isSessionBasedConference =
      event.field_award_credit_for_conferenc &&
      event.field_award_credit_for_conferenc === "No"
    if (isSessionBasedConference) {
      bundle = "conf_session_credit"
      query.filter = {
        "field_conf_user_event.id": event.uuid,
      }
    }

    let attemptedFetch = await fetchWrapper.get(
      "/api/certificate_entity/" + bundle + "?" + qs.stringify(query)
    )

    if (attemptedFetch.ok) {
      let existingCert = await attemptedFetch.json()
      if (existingCert.data[0]) {
        return existingCert.data[0]
      }
    }

    let totalCredit = Number(event.field_credit.replace("min", ""))

    // Figure out total individual session credit if this is a session-PD based conference
    if (isSessionBasedConference) {
      totalCredit = 0

      let query = {
        sort_by: "field_event_date_time_value",
        sort_order: "ASC",
        field_parent_event_target_id: event.id_1,
      }

      let sessionResponse = await fetchWrapper.get(
        "/api/user-events-learner/user?" + qs.stringify(query)
      )

      if (sessionResponse.ok) {
        let sessionData = await sessionResponse.json()

        !sessionData.rows.content &&
          sessionData.rows.forEach((conferenceEvent) => {
            totalCredit += Number(conferenceEvent.field_credit)
          })
      }
    }

    const field_enabled_certificate_values = []
    if (event.field_enabled_certificate_values.includes("PD credit")) {
      field_enabled_certificate_values.push("pd_credit")
    }
    if (event.field_enabled_certificate_values.includes("Org Logo")) {
      field_enabled_certificate_values.push("org_logo")
    }
    if (event.field_enabled_certificate_values.includes("Signature")) {
      field_enabled_certificate_values.push("signature")
    }

    let body = {
      data: {
        type: "certificate_entity--" + eventBundle,
        attributes: {
          name: event.name,
          field_credit: totalCredit,
          field_enabled_certificate_values,
          field_event_date_time: {
            value: moment(event.field_event_date_time_value).format(),
            end_value: moment(event.field_event_end_date_time_value).format(),
          },
          field_image_url: event.field_event_image,
        },
        relationships: {},
      },
    }

    if (isSessionBasedConference) {
      body.data.relationships = {
        field_conf_user_event: {
          data: {
            type: "mobile_mind_user_event--" + eventBundle,
            id: event.uuid,
          },
        },
      }
    }

    try {
      if (isSharing) {
        let response = await fetchWrapper.post(
          "/api/certificate_entity/" + bundle,
          token,
          JSON.stringify(body)
        )

        if (response.ok) {
          let data = await response.json()
          return data.data
        }
      }

      return body.data
    } catch (err) {
      console.log(err)
    }
  }
}

export function createLearningPathCertificate(learningPath, mobile) {
  return async (dispatch, getState) => {
    const token = mobile
      ? getState().session.mobileToken.csrf_token
      : getState().session.token

    let targetId = learningPath.field_user_lp_uuid
    const field_enabled_certificate_values =
      learningPath.field_enabled_certificate_values

    let body = {
      data: {
        type: "certificate_entity--learning_path",
        attributes: {
          field_enabled_certificate_values,
        },
        relationships: {
          field_user_learning_path: {
            data: {
              type: "user_learning_path--user_learning_path",
              id: targetId,
            },
          },
        },
      },
    }

    try {
      let response = await fetchWrapper.post(
        "/api/certificate_entity/learning_path",
        token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "profileSlice/addCertificateToPath",
          payload: {
            learningPath,
            certificate: data.data,
          },
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Create a user_learning_path entity for a learning path
 * @param {object} learningPath The Learning Path entity to reference
 */
export function createUserLearningPath(learningPath, isMobile) {
  return async (dispatch, getState) => {
    let token = isMobile
      ? getState().session.mobileToken.csrf_token
      : getState().session.token

    const uuid = learningPath.attributes
      ? learningPath.id
      : learningPath.field_lp_uuid
    const drupal_internal__id = learningPath.attributes
      ? learningPath.attributes.drupal_internal__id
      : learningPath.field_lp_id

    let userLearningPath = {
      data: {
        type: "user_learning_path--user_learning_path",
        attributes: {
          name:
            "Learning Path: " +
            drupal_internal__id +
            " / uid: " +
            getState().session.user.attributes.drupal_internal__uid,
          status: true,
        },
        relationships: {
          field_learning_path: {
            data: {
              type: "learning_path--learning_path",
              id: uuid,
            },
          },
          field_user: {
            data: {
              type: "user--user",
              id: getState().session.user.id,
            },
          },
        },
      },
    }

    let body = JSON.stringify(userLearningPath)

    try {
      let response = await fetchWrapper.post(
        "/api/user_learning_path/user_learning_path",
        token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "learningPathSlice/createUserLearningPath",
          payload: data.data,
        })
        dispatch({
          type: "learningPathSingleSlice/createUserLearningPath",
          payload: data.data,
        })

        dispatch({
          type: "learningPathSingleSlice/createULP",
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Create a user_quest entity for a quest
 * @param {object} quest The quest to reference
 */
export function createUserQuest(quest, isMobile) {
  return async (dispatch, getState) => {
    let token = isMobile
      ? getState().session.mobileToken.csrf_token
      : getState().session.token

    let userQuest = {
      data: {
        type: "mm_user_quest--user_quest",
        attributes: {
          title:
            "Quest: " +
            quest.field_quest_name +
            " / uid: " +
            getState().session.user.attributes.drupal_internal__uid,
          status: true,
        },
        relationships: {
          field_quest: {
            data: {
              type: "mobilemind_quest--quest",
              id: quest.uuid,
            },
          },
          field_user: {
            data: {
              type: "user--user",
              id: getState().session.user.id,
            },
          },
        },
      },
    }

    let body = JSON.stringify(userQuest)

    try {
      let response = await fetchWrapper.post(
        "/api/mm_user_quest/user_quest",
        token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}
