import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import moment from "moment"
import classNames from "classnames"
import theme from "../../../theme/theme"
import Loading from "../../Loading"
import SocialShare from "./SocialShare"
import he from "he"

import "../styles/certificate.scss"

import { createEventCertificate } from "../../../actions/user"

import { UserEventsLearner } from "../../../types/events"
import TimeCapsule from "../../TimeCapsule"
import { convertMinutesToHoursMinutes } from "../../../functions"
import Values from "values.js"
import { CategoryTaxonomy } from "../../../types/taxonomy/category"
import { BackpackUserData } from "../../../types/backpack"
import { Box, Typography } from "@mui/material"

const primary = new Values(theme.palette.primary.main)

type CertificateProps = {
  event: UserEventsLearner
  isSharing: boolean
  category?: CategoryTaxonomy
  userData: BackpackUserData
  // eslint-disable-next-line
  user: any
  // eslint-disable-next-line
  certificate?: any
  // eslint-disable-next-line
  session: any
}

function EventCertificate(props: CertificateProps) {
  const { event, category, user, isSharing, session, userData } = props
  const [certificateGenerated, setCertificateGenerated] = useState(false)
  const [newCertId, setNewCertId] = useState("")
  const [fetchedCert, setFetchCert] = useState({ data: null })

  const dispatch = useDispatch()

  useEffect(() => {
    if (!newCertId) {
      event && createCertificate()
    }
  }, [])

  const createCertificate = async () => {
    const newCert = await dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createEventCertificate(event, null, isSharing)
    )
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setNewCertId(newCert.data ? newCert.data.id : newCert.id)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFetchCert(newCert)
      setCertificateGenerated(true)
    }, 1000)
  }

  let certificate
  if (fetchedCert) {
    certificate = fetchedCert.data ? fetchedCert.data : fetchedCert
  }

  if (certificate && !certificate.data) {
    certificate = props.certificate
  }

  if (event || certificate) {
    // Certificate customization stuff
    const field_enabled_certificate_values = event
      ? event.field_enabled_certificate_values
      : certificate.attributes?.field_enabled_certificate_values?.join()

    const logoPath = session.group?.field_logo_path
      ? process.env.REACT_APP_API_URL + session.group.field_logo_path[0].value
      : process.env.REACT_APP_API_URL + certificate.attributes?.field_logo_path

    const fontName = session.group?.field_signature_font
      ? session.group?.field_signature_font[0]?.value
      : certificate.attributes?.field_signature_font

    const signatureName = session.group?.field_signature_name
      ? session.group?.field_signature_name[0]?.value
      : certificate.attributes?.field_signature_name

    const signatureTitle = session.group?.field_signature_name
      ? session.group?.field_signature_title[0]?.value
      : certificate.attributes?.field_signature_title

    const imageUrl = event
      ? event.field_event_image
      : certificate.attributes?.field_image_url

    const name = event ? event.name : certificate.attributes?.name

    const eventDate = event
      ? event.field_event_date_time_value
      : certificate.attributes?.field_earned

    let credit =
      certificate && certificate.attributes
        ? certificate.attributes.field_credit
        : event && event.field_credit

    if (!credit) {
      credit =
        fetchedCert &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchedCert.attributes &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchedCert.attributes.field_credit
    }

    if (credit) {
      credit =
        credit && credit.length ? Number(credit.replace("min", "")) : credit
    }

    let userName = user.attributes
      ? user.attributes.field_first_name + " " + user.attributes.field_last_name
      : certificate.attributes.field_user_name

    if (userData) {
      userName =
        userData.field_first_name_value + " " + userData.field_last_name_value
    }

    const showCapsule =
      !window.location.href.includes("certificate-share") &&
      ((credit && Number(credit) > 0) || (category && category.image))

    let shareCertId = newCertId

    if (!shareCertId && certificate) {
      shareCertId = certificate.id
    }

    const shareUrl =
      process.env.REACT_APP_TEACHER_URL +
      "/certificate-share/event/" +
      shareCertId

    return (
      <div className={classNames("certificateWrapper", "print")}>
        <div className="certificateBadge certificateEvent">
          {imageUrl ? (
            <img
              style={{
                opacity: 0.2,
              }}
              src={process.env.REACT_APP_API_URL + imageUrl}
              className="bg-badge bg-event"
            />
          ) : (
            <div
              style={{
                opacity: 0.2,
                backgroundPosition: "center",
                backgroundImage:
                  "url(\"data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='" +
                  theme.palette.secondary.main.replace("#", "%23") +
                  "' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E\")",
              }}
              className="bg-badge"
            />
          )}

          <h2>
            {he.decode(userName)}
            <strong>Attended an event</strong>
          </h2>

          <div className="badgeImageWrapper">
            {imageUrl ? (
              <div
                className="eventImage"
                style={{
                  backgroundImage:
                    "url(" + process.env.REACT_APP_API_URL + imageUrl + ")",
                }}
              />
            ) : (
              <span
                className="eventImage empty"
                style={{
                  backgroundColor: event
                    ? primary.shade(15).hexString()
                    : primary.hexString(),
                }}
              />
            )}

            {showCapsule && (
              <div
                className="capsuleWrapper"
                style={{ printColorAdjust: "exact", backgroundColor: "black" }}
              >
                {category && category.image && (
                  <>
                    <span
                      style={{
                        marginLeft: 0,
                        marginRight:
                          field_enabled_certificate_values.includes("credit") &&
                          credit &&
                          Number(credit) > 0
                            ? 20
                            : 0,
                      }}
                      className="categoryInfo"
                    >
                      <span
                        className="category-icon"
                        style={{
                          background:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            category.image.attributes.uri.url +
                            ")",
                        }}
                      />
                      {he.decode(category.attributes.name)}
                    </span>
                  </>
                )}
                {credit &&
                field_enabled_certificate_values.includes("credit") &&
                Number(credit) > 0 ? (
                  <TimeCapsule
                    hours={convertMinutesToHoursMinutes(credit).hours}
                    minutes={convertMinutesToHoursMinutes(credit).minutes}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          <footer
            style={{
              position: "relative",
              top: -10,
              padding: 0,
              marginBottom: 10,
            }}
          >
            <span className="title">{he.decode(name)}</span>
            <span
              className="timestamp"
              style={{ display: "block", marginTop: -3 }}
            >
              {moment(
                eventDate
                  ? eventDate
                  : certificate.attributes.field_event_date_time?.value
              ).format("MMMM D, YYYY")}
            </span>
          </footer>

          <div
            style={{
              padding: "0 20px",
              position: "relative",
              zIndex: 3,
              marginTop: -15,
              marginBottom: 20,
              display: "flex",
              alignItems: field_enabled_certificate_values.includes("ignature")
                ? "center"
                : "space-between",
              justifyContent: "center",
            }}
          >
            <Box
              style={{
                flex: field_enabled_certificate_values.includes("ignature")
                  ? 1
                  : "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              {field_enabled_certificate_values.includes("ogo") ? (
                <img
                  src={logoPath}
                  style={{
                    borderRadius: "6px",
                    overflow: "hidden",
                    height: 50,
                    flexShrink: 0,
                    marginTop: -5,
                  }}
                  alt={
                    session.group?.label
                      ? session.group.label[0].value
                      : "Organization"
                  }
                />
              ) : (
                <img
                  src={theme.mixins.images.logo}
                  style={{
                    width: 125,
                    flexShrink: 0,
                    marginTop: -5,
                  }}
                  alt="MobileMind"
                />
              )}
            </Box>
            {field_enabled_certificate_values.includes("ignature") && (
              <Box className="fadeIn" style={{ marginLeft: 15 }}>
                <Typography
                  style={{ fontSize: 20 }}
                  className={classNames(
                    "signature-text Mrs-Saint-Delafield",
                    fontName
                  )}
                >
                  {signatureName}
                </Typography>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 8,
                    textAlign: "right",
                  }}
                >
                  {signatureTitle}
                </Typography>
              </Box>
            )}
          </div>
        </div>

        {isSharing && (
          <div className="shareLink">
            {certificateGenerated ? (
              <>
                <strong>Copy the link below to share your certificate!</strong>
                <SocialShare shareUrl={shareUrl} event={event} />
              </>
            ) : (
              <Loading message={"Preparing certificate..."} />
            )}
          </div>
        )}
      </div>
    )
  }
  return null
}

export default EventCertificate
