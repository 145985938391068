import SidebarSectioned from "@mobilemind/common/src/components/SidebarSectioned"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"

import classNames from "classnames"
import Masonry from "react-masonry-css"
import {
  fetchQuickActions,
  getDashboardMarketplace,
  resetSearch,
} from "../../store/reducers/dashboard"
import { changeTab } from "../../store/reducers/navbar"
import {
  addRemoveQuickAction,
  updateQuickActions,
} from "../../store/reducers/session"
import LearnerSearch from "./LearnerSearch"

import "./dashboard.scss"

import ButtonSmall from "../../components/ButtonSmall"
import { setCategoryModalOpen } from "../../store/reducers/categories"
import ProductTour from "../productTour/ProductTour"
import WidgetChallenges from "./WidgetChallenges"
import WidgetModalTips from "./WidgetModalTips"
import WidgetSupport from "./WidgetSupport"
import WidgetUpcomingEvents from "./WidgetUpcomingEvents"
import WidgetMarketplace from "./WidgetMarketplace"

const DashboardLayout = (props: any) => {
  const dashboard = useAppSelector((state) => state.dashboard)
  const session = useAppSelector((state) => state.session)
  const hideEventsForOrg =
    session.group &&
    session.group.field_hide_events &&
    session.group.field_hide_events[0]?.value
  const dispatch = useAppDispatch()
  const [numColumns, setNumColumns] = useState(3)
  const [isActionPanelOut, slideActionPanel] = useState(false)

  const {
    isOrgAdmin,
    canCreate,
    canReport,
    canCreateGoals,
    canReview,
    isEventPersonnel,
    canViewBackpack,
    isPartner,
    canSchedule,
  } = session

  const isObserver =
    session.orgRoles.includes("organization-observation_admin") ||
    session.orgRoles.includes("organization-observer") ||
    session.groupRoles.includes("group-observer") ||
    session.groupRoles.includes("group-observation_admin")

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    dispatch(resetSearch())
    dispatch(changeTab(""))
    dispatch(fetchQuickActions())
    dispatch(getDashboardMarketplace({}))
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [dispatch])

  function handleResize() {
    setNumColumns(window.outerWidth > 1420 ? 3 : 2)
  }

  let availableQuickActions: any[] = []

  dashboard.quickActions.forEach((action: any) => {
    const name = action.attributes.name.toLowerCase()

    if (isOrgAdmin && !isPartner) {
      availableQuickActions.push(action)
    } else {
      if (
        (name.includes("create") ||
          name.includes("post") ||
          name.includes("courses") ||
          name.includes("badges") ||
          name.includes("learning paths") ||
          name.includes("announcements")) &&
        !name.includes("goal") &&
        canCreate &&
        !isObserver &&
        !isPartner
      ) {
        availableQuickActions.push(action)
      }

      if (
        isPartner &&
        (name.includes("courses") ||
          name.includes("create course") ||
          name.includes("create badge") ||
          name.includes("badges") ||
          name.includes("learning path"))
      ) {
        availableQuickActions.push(action)
      }

      if (
        name.includes("goal") &&
        canCreateGoals &&
        !isPartner &&
        !isObserver
      ) {
        availableQuickActions.push(action)
      }

      if (
        name.includes("schedule") &&
        canSchedule &&
        !isPartner &&
        !isObserver
      ) {
        availableQuickActions.push(action)
      }
      if (
        !isPartner &&
        name.includes("events") &&
        (canSchedule || isObserver || isEventPersonnel)
      ) {
        availableQuickActions.push(action)
      }
      if (!isPartner && name.includes("review") && canReview) {
        availableQuickActions.push(action)
      }

      if (!isPartner && name.includes("groups") && isOrgAdmin) {
        availableQuickActions.push(action)
      }

      if (
        (name.includes("leaderboards") ||
          name.includes("progress") ||
          name.includes("access")) &&
        canReport &&
        !isObserver &&
        !isPartner
      ) {
        availableQuickActions.push(action)
      }

      if (isObserver && name.includes("progress")) {
        availableQuickActions.push(action)
      }

      // Partner reports/categories
      if (
        (isPartner && name.includes("categories")) ||
        (name.includes("leaderboards") && !isObserver) ||
        name.includes("progress")
      ) {
        availableQuickActions.push(action)
      }
    }
  })

  let uniqueQuickActions: any[] = []

  availableQuickActions.forEach((action) => {
    if (!uniqueQuickActions.find((existing) => existing.id === action.id)) {
      uniqueQuickActions.push(action)
    }
  })

  availableQuickActions = uniqueQuickActions

  const sidebarSections = [
    {
      title: "Quick Actions",
      id: "quickActions",
      children: session.user.quickActions
        ? session.user.quickActions.map((action: any) => {
            return {
              iconClass:
                action.attributes.name.replaceAll(" ", "-").toLowerCase() +
                " quick-action-icon",
              title: action.attributes.name,
              onClick: () => {
                if (
                  action.attributes.field_url &&
                  action.attributes.field_url.includes(
                    process.env.REACT_APP_API_URL
                  )
                ) {
                  window.location.href = action.attributes.field_url
                } else if (action.attributes.name.includes("Categories")) {
                  dispatch(setCategoryModalOpen(true))
                } else {
                  props.history.push(action.attributes.field_url)
                }
              },
            }
          })
        : [],
    },
  ]

  return (
    <div
      id="tour-widgets"
      className={classNames(
        dashboard.learnerSearch.query && "search-active",
        !canViewBackpack && "hideLearnerSearch",
        isActionPanelOut && "action-panel-out",
        "page dashboard-layout"
      )}
    >
      <ProductTour />

      {canViewBackpack && (
        <div
          className="overlay"
          onClick={() => {
            dashboard.learnerSearch.query && dispatch(resetSearch())
          }}
        />
      )}

      <div
        className={classNames(
          isActionPanelOut && "active",
          "quick-action-panel"
        )}
      >
        <ul>
          {availableQuickActions.map((action: any) => {
            const isSelected = session.user.quickActions
              ? session.user.quickActions.find(
                  (existing: any) => existing.id === action.id
                )
              : false

            const existingActions = session.user.quickActions
              ? [...session.user.quickActions]
              : []

            let shouldReturn = true

            if (
              session.group &&
              session.group.field_hide_events &&
              session.group.field_hide_events[0]?.value
            ) {
              if (
                action.attributes.name.includes("Event") ||
                action.attributes.name.includes("Schedule")
              ) {
                shouldReturn = false
              }
            }

            if (shouldReturn) {
              return (
                <li
                  key={action.id}
                  onClick={() => {
                    if (isSelected) {
                      const updatedQuickActions = existingActions.filter(
                        (existing: any) => existing.id !== action.id
                      )
                      dispatch(addRemoveQuickAction({ updatedQuickActions }))
                      dispatch(updateQuickActions({ updatedQuickActions }))
                    } else {
                      const updatedQuickActions = existingActions.concat([
                        action,
                      ])
                      dispatch(addRemoveQuickAction({ updatedQuickActions }))
                      dispatch(updateQuickActions({ updatedQuickActions }))
                    }
                  }}
                  className={classNames(isSelected && "active")}
                >
                  <span
                    className={classNames(
                      "icon quick-action-icon",
                      action.attributes.name.replaceAll(" ", "-").toLowerCase()
                    )}
                  ></span>
                  <strong>{action.attributes.name}</strong>
                </li>
              )
            }
            return null
          })}
        </ul>
      </div>
      <div
        className={classNames("action-overlay", isActionPanelOut && "active")}
        onClick={() => {
          slideActionPanel(!isActionPanelOut)
        }}
      />

      {!isActionPanelOut && (
        <div className="quickActionEdit">
          <ButtonSmall onClick={() => slideActionPanel(true)}>
            <span className="icon gear" />
            Edit
          </ButtonSmall>
        </div>
      )}

      <SidebarSectioned activeItem={"none"} sections={sidebarSections} />

      {canViewBackpack && <LearnerSearch />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Masonry
          breakpointCols={numColumns}
          className="masonry-grid"
          columnClassName="masonry-column"
        >
          <WidgetModalTips {...props} />
          {canReview && !isPartner && <WidgetChallenges />}

          {(canSchedule || isEventPersonnel || isObserver) &&
            !isPartner &&
            !hideEventsForOrg && <WidgetUpcomingEvents />}

          {isOrgAdmin && (
            <WidgetMarketplace marketplace={dashboard.widgets.marketplace} />
          )}

          {isOrgAdmin && <WidgetSupport />}
        </Masonry>
      }
    </div>
  )
}

export default DashboardLayout
